/**
 *    SPDX-License-Identifier: Apache-2.0
 */
/* eslint-disable */
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import Timeago from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import zhStrings from 'react-timeago/lib/language-strings/zh-CN';
import { Badge } from 'reactstrap';
import blockicon from '../../static/images/common/blockicon.png';
const formatter = buildFormatter(zhStrings);
/* istanbul ignore next */
const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
		root: {
			width: '100%',
			maxWidth: 360,
			backgroundColor: theme.palette.background.paper,
			padding: `40px 20px`
		},
		avatarBlue: {
			backgroundColor: '#1C3860'
		},
		panel: {
			color: dark ? '#ffffff' : undefined,
			backgroundColor: dark ? '#5e558e' : undefined
		},
		badge: {
			marginLeft: '60% !important',
			color: dark ? '#ffffff' : undefined,
			backgroundColor: dark ? '#242036' : undefined
		},
		time: {
			color: dark ? '#ffffff' : undefined,
			backgroundColor: dark ? '#242036' : undefined
		}
	};
};

export class NotificationsPanel extends Component {
	/* istanbul ignore next */
	avatarIcon = (type, classes) => {
		if (type === 'block') {
			return (
				<Avatar className={classes.avatarBlue}>
					<FontAwesome name="cube" />{' '}
				</Avatar>
			);
		} else {
			return (
				<Avatar>
					<FontAwesome name="exclamation" />{' '}
				</Avatar>
			);
		}
	};

	/* istanbul ignore next */
	render() {
		const { classes, notifications } = this.props;

		if (notifications.length === 0) {
			return (
				<div className={classes.panel}>
					<div className={classes.root}>
						<List component="nav" className={classes.panel}>
							<ListItem className="j-center">
								<Typography variant="title" className={classes.panel}>
									暂无通知
								</Typography>
							</ListItem>
						</List>
					</div>
				</div>
			);
		}

		/* istanbul ignore next */
		return (
			<div className={classes.panel}>
				<div className={classes.root}>
					<h5>
						通知 (<span style={{ color: '#4E4CB3' }}>{notifications.length}</span>)
					</h5>
					<List component="nav" className={classes.panel}>
						{notifications.map((notify, index) => (
							<div key={index}>
								<ListItem key={index} button className={classes.panel}>
									<img
										src={blockicon}
										alt=""
										width="32"
										style={{
											padding: 5,
											marginRight: 16,
											borderRadius: '100%',
											background: '#4E4CB3'
										}}
									/>
									<ListItemText
										className={classes.panel}
										primary={notify.title}
										secondary={<span className="mb-1">{notify.message}</span>}
									/>
								</ListItem>
								<Badge className={classes.badge}>
									<Timeago
										className={classes.time}
										date={notify.time}
										live={false}
										minPeriod={60}
										formatter={formatter}
									/>
								</Badge>
							</div>
						))}
					</List>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(NotificationsPanel);
