/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FontAwesome from 'react-fontawesome';
import JSONTree from 'react-json-tree';
import { Card, CardBody, CardTitle, Table } from 'reactstrap';
import Modal from '../Styled/Modal';
import { transactionType } from '../types';
/* eslint-disable */
const readTheme = {
	base00: '#f3f3f3',
	base01: '#2e2f30',
	base02: '#515253',
	base03: '#737475',
	base04: '#959697',
	base05: '#b7b8b9',
	base06: '#dadbdc',
	base07: '#fcfdfe',
	base08: '#e31a1c',
	base09: '#e6550d',
	base0A: '#dca060',
	base0B: '#31a354',
	base0C: '#80b1d3',
	base0D: '#3182bd',
	base0E: '#756bb1',
	base0F: '#b15928'
};
const writeTheme = {
	base00: '#ffffff',
	base01: '#2e2f30',
	base02: '#515253',
	base03: '#737475',
	base04: '#959697',
	base05: '#b7b8b9',
	base06: '#dadbdc',
	base07: '#fcfdfe',
	base08: '#e31a1c',
	base09: '#e6550d',
	base0A: '#dca060',
	base0B: '#31a354',
	base0C: '#80b1d3',
	base0D: '#3182bd',
	base0E: '#756bb1',
	base0F: '#b15928'
};
/* eslint-enable */
const styles = theme => ({
	listIcon: {
		color: '#ffffff',
		marginRight: 20
	},
	JSONtree: {
		'& ul': {
			backgroundColor: 'transparent !important',
			color: '#fff'
		}
	}
});

const reads = {
	color: '#2AA233'
};
const writes = {
	color: '#DD8016'
};

export class TransactionView extends Component {
	handleClose = () => {
		const { onClose } = this.props;
		onClose();
	};

	render() {
		const { transaction, classes } = this.props;
		if (transaction && !transaction.read_set) {
			return (
				<Modal>
					{modalClasses => (
						<div>
							<CardTitle className={modalClasses.title}>
								<div className="titleLeftLine pl-30">交易明细</div>
								{/* eslint-disable   */}
								<a onClick={this.handleClose} className={modalClasses.close}>
									×
								</a>
								{/* eslint-disable   */}
							</CardTitle>
							<div align="center">
								<CardBody className={modalClasses.body}>
									<span>
										{' '}
										<FontAwesome name="circle-o-notch" size="3x" spin />
									</span>
								</CardBody>
							</div>
						</div>
					)}
				</Modal>
			);
		}
		if (transaction) {
			let baseUrl =
				window.location.protocol +
				'//' +
				window.location.hostname +
				':' +
				window.location.port;
			let directLink =
				baseUrl + '/?tab=transactions&transId=' + transaction.txhash;
			return (
				<Modal>
					{modalClasses => (
						<div className={modalClasses.dialog}>
							<Card className={modalClasses.card}>
								<CardTitle className={modalClasses.title}>
									<div className="titleLeftLine pl-30">交易明细</div>
									<a onClick={this.handleClose} className={modalClasses.close}>
										×
									</a>
								</CardTitle>
								<CardBody className={modalClasses.body}>
									<Table hover>
										<tbody>
											<tr>
												<th>交易编号:</th>
												<td>
													{transaction.txhash}
													<button type="button" className={modalClasses.copyBtn}>
														<div className={modalClasses.copy}>Copy</div>
														<div className={modalClasses.copied}>Copied</div>
														<CopyToClipboard text={transaction.txhash}>
															<FontAwesome name="clipboard" />
														</CopyToClipboard>
													</button>
												</td>
											</tr>
											<tr>
												<th>验证码:</th>
												<td>{transaction.validation_code}</td>
											</tr>
											<tr>
												<th style={{ width: 120 }}>有效提案哈希:</th>
												<td>{transaction.payload_proposal_hash}</td>
											</tr>
											<tr>
												<th>创建者MSP:</th>
												<td>{transaction.creator_msp_id}</td>
											</tr>
											<tr>
												<th>背书人:</th>
												<td>{transaction.endorser_msp_id}</td>
											</tr>
											<tr>
												<th>链码名称:</th>
												<td>{transaction.chaincodename}</td>
											</tr>
											<tr>
												<th>类型:</th>
												<td>{transaction.type}</td>
											</tr>
											<tr>
												<th>时间:</th>

												<td>
													{moment(transaction.createdt)
														.add(8, 'hours')
														.format('YYYY-MM-DD HH:mm:ss')}
												</td>
											</tr>
											<tr>
												<th>直接链接:</th>
												<td>
													{directLink}
													<button type="button" className={modalClasses.copyBtn}>
														<div className={modalClasses.copy}>Copy</div>
														<div className={modalClasses.copied}>Copied</div>
														<CopyToClipboard text={directLink}>
															<FontAwesome name="clipboard" />
														</CopyToClipboard>
													</button>
												</td>
											</tr>
											<tr>
												<th style={reads}>读道:</th>
												<td className={classes.JSONtree}>
													<JSONTree
														data={transaction.read_set}
														theme={readTheme}
														invertTheme={false}
													/>
												</td>
											</tr>
											<tr>
												<th style={writes}>写道:</th>
												<td className={classes.JSONtree}>
													<JSONTree
														data={transaction.write_set}
														theme={writeTheme}
														invertTheme={false}
													/>
												</td>
											</tr>
										</tbody>
									</Table>
								</CardBody>
							</Card>
						</div>
					)}
				</Modal>
			);
		}
		return (
			<Modal>
				{modalClasses => (
					<div>
						<CardTitle className={modalClasses.title}>
							<div className="titleLeftLine pl-30">交易明细</div>

							<a onClick={this.handleClose} className={modalClasses.close}>
								×
							</a>
						</CardTitle>
						<div align="center">
							<CardBody className={modalClasses.body}>
								<span>
									{' '}
									<FontAwesome name="circle-o-notch" size="3x" spin />
								</span>
							</CardBody>
						</div>
					</div>
				)}
			</Modal>
		);
	}
}

TransactionView.propTypes = {
	transaction: transactionType
};

TransactionView.defaultProps = {
	transaction: null
};

export default withStyles(styles)(TransactionView);
