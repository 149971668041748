import { useEffect } from 'react';

export const useTranslateSelectRow = () => {
	useEffect(() => {
		const options = document.querySelectorAll(
			'.select-wrap.-pageSizeOptions select option'
		);

		options.forEach((it, index) => {
			if (index === 0) it.style.display = 'none';
			it.innerHTML = it.value + '条';
		});
		const noData = document.querySelector('.rt-noData');
		if (noData) noData.innerHTML = '暂无数据';
	});
};
