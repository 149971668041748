/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/App';
import Theme from './components/Theme';
import { unregister } from './registerServiceWorker';
import authOperations from './state/redux/auth/operations';
import createStore from './state/store';
const mode = localStorage.getItem('theme-mode') || 'light';
const store = createStore({ theme: { mode } });

store.subscribe(themeSideEffect(store));

function themeSideEffect(storeObj) {
	let theme;
	return () => {
		const state = storeObj.getState();
		if (theme !== state.theme) {
			theme = state.theme;
			localStorage.setItem('theme-mode', theme.mode);
		}
	};
}

store.dispatch(authOperations.network());

unregister();

ReactDOM.render(
	<Provider store={store}>
		<Theme>
			<ConfigProvider locale={zhCN}>
				<App />
			</ConfigProvider>
		</Theme>
	</Provider>,
	document.getElementById('root')
);
