/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import find from 'lodash/find';
import matchSorter from 'match-sorter';
import moment from 'moment';
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { isNull } from 'util';

import { DatePicker } from 'antd';
import MultiSelect from '../Styled/MultiSelect';
import ReactTable from '../Styled/Table';
import {
	blockListType,
	currentChannelType,
	getTransactionType,
	transactionType
} from '../types';
import BlockView from '../View/BlockView';
import TransactionView from '../View/TransactionView';

/* istanbul ignore next */
const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
		hash: {
			'&, & li, & ul': {
				overflow: 'visible !important'
			}
		},
		partialHash: {
			textAlign: 'center',
			position: 'relative !important',
			'&:hover $fullHash': {
				display: 'block',
				position: 'absolute !important',
				padding: '4px 4px',
				backgroundColor: dark ? '#5e558e' : '#000000',
				marginTop: -30,
				marginLeft: -215,
				borderRadius: 8,
				color: '#ffffff',
				opacity: dark ? 1 : undefined
			},
			'&:hover $lastFullHash': {
				display: 'block',
				position: 'absolute !important',
				padding: '4px 4px',
				backgroundColor: dark ? '#5e558e' : '#000000',
				marginTop: -30,
				marginLeft: -415,
				borderRadius: 8,
				color: '#ffffff',
				opacity: dark ? 1 : undefined
			}
		},
		fullHash: {
			display: 'none'
		},
		lastFullHash: {
			display: 'none'
		},
		filter: {
			width: '100%',
			textAlign: 'center',
			margin: '0px !important'
		},
		filterButton: {
			opacity: 0.8,
			margin: 'auto',
			width: '100% !important',
			'margin-bottom': '4px'
		},
		searchButton: {
			opacity: 0.8,
			margin: 'auto',
			width: '100% !important',
			backgroundColor: dark ? undefined : '#4E4CB3',
			'margin-bottom': '4px'
		},
		filterElement: {
			textAlign: 'center',
			display: 'flex',
			padding: '0px !important',
			'& > div': {
				width: '100% !important',
				marginTop: 20
			},
			'& .label': {
				margin: '25px 10px 0px 10px'
			}
		}
	};
};

export class Blocks extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dialogOpen: false,
			dialogOpenBlockHash: false,
			err: false,
			search: false,
			to: moment(),
			orgs: [],
			options: [],
			filtered: [],
			sorted: [],
			from: moment().subtract(1, 'days'),
			blockHash: {},
			fromKey: Date.now() + 1,
			endKey: Date.now() + 2
		};
	}

	componentDidMount() {
		const { blockList } = this.props;
		const selection = {};
		blockList.forEach(element => {
			selection[element.blocknum] = false;
		});
		const opts = [];
		this.props.transactionByOrg.forEach(val => {
			opts.push({ label: val.creator_msp_id, value: val.creator_msp_id });
		});
		this.setState({ selection, options: opts });
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.state.search &&
			this.props.currentChannel !== prevProps.currentChannel
		) {
			if (this.interval !== undefined) {
				clearInterval(this.interval);
			}
			this.interval = setInterval(() => {
				this.searchBlockList(this.props.currentChannel);
			}, 60000);
			this.searchBlockList(this.props.currentChannel);
		}
	}

	componentWillUnmount() {
		clearInterval(this.interVal);
	}

	handleCustomRender(selected, options) {
		if (selected.length === 0) {
			return '选择组织';
		}
		if (selected.length === options.length) {
			return '全部';
		}

		return selected.join(',');
	}

	searchBlockList = async channel => {
		let query = `from=${new Date(this.state.from).toString()}&&to=${new Date(
			this.state.to
		).toString()}`;
		for (let i = 0; i < this.state.orgs.length; i++) {
			query += `&&orgs=${this.state.orgs[i]}`;
		}
		let channelhash = this.props.currentChannel;
		if (channel !== undefined) {
			channelhash = channel;
		}
		await this.props.getBlockListSearch(channelhash, query);
	};

	handleDialogOpen = async tid => {
		const { getTransaction, currentChannel } = this.props;
		await getTransaction(currentChannel, tid);
		this.setState({ dialogOpen: true });
	};

	handleMultiSelect = value => {
		this.setState({ orgs: value });
	};

	handleDialogClose = () => {
		this.setState({ dialogOpen: false });
	};

	handleSearch = async () => {
		if (this.interval !== undefined) {
			clearInterval(this.interval);
		}
		this.interval = setInterval(() => {
			this.searchBlockList();
		}, 60000);
		await this.searchBlockList();
		this.setState({ search: true });
	};

	handleClearSearch = () => {
		if (this.interval !== undefined) {
			clearInterval(this.interval);
		}
		this.setState({
			search: false,
			to: moment(),
			orgs: [],
			err: false,
			from: moment().subtract(1, 'days'),
			fromKey: Date.now() + 1,
			endKey: Date.now() + 2
		});
	};

	handleDialogOpenBlockHash = blockHash => {
		const blockList = this.state.search
			? this.props.blockListSearch
			: this.props.blockList;
		const data = find(blockList, item => item.blockhash === blockHash);

		this.setState({
			dialogOpenBlockHash: true,
			blockHash: data
		});
	};

	handleDialogCloseBlockHash = () => {
		this.setState({ dialogOpenBlockHash: false });
	};

	handleEye = (row, val) => {
		const { selection } = this.state;
		const data = Object.assign({}, selection, { [row.index]: !val });
		this.setState({ selection: data });
	};

	reactTableSetup = classes => [
		{
			Header: '块号',
			accessor: 'blocknum',
			filterMethod: (filter, rows) =>
				matchSorter(
					rows,
					filter.value,
					{ keys: ['blocknum'] },
					{ threshold: matchSorter.rankings.SIMPLEMATCH }
				),
			filterAll: true,
			width: 150
		},
		{
			Header: '通道名称',
			accessor: 'channelname',
			filterMethod: (filter, rows) =>
				matchSorter(
					rows,
					filter.value,
					{ keys: ['channelname'] },
					{ threshold: matchSorter.rankings.SIMPLEMATCH }
				),
			filterAll: true
		},
		{
			Header: '发送数',
			accessor: 'txcount',
			filterMethod: (filter, rows) =>
				matchSorter(
					rows,
					filter.value,
					{ keys: ['txcount'] },
					{ threshold: matchSorter.rankings.SIMPLEMATCH }
				),
			filterAll: true,
			width: 150
		},
		{
			Header: '数据哈希',
			accessor: 'datahash',
			className: classes.hash,
			Cell: row => (
				<span>
					<ul className={classes.partialHash} href="#/blocks">
						<div className={classes.fullHash} id="showTransactionId">
							{row.value}
						</div>{' '}
						{row.value.slice(0, 6)} {!row.value ? '' : '... '}
					</ul>{' '}
				</span>
			),
			filterMethod: (filter, rows) =>
				matchSorter(
					rows,
					filter.value,
					{ keys: ['datahash'] },
					{ threshold: matchSorter.rankings.SIMPLEMATCH }
				),
			filterAll: true
		},
		{
			Header: '块哈希',
			accessor: 'blockhash',
			className: classes.hash,
			Cell: row => (
				<span>
					<a
						data-command="block-partial-hash"
						className={classes.partialHash}
						onClick={() => this.handleDialogOpenBlockHash(row.value)}
						href="#/blocks"
					>
						<div className={classes.fullHash} id="showTransactionId">
							{row.value}
						</div>{' '}
						{row.value.slice(0, 6)} {!row.value ? '' : '... '}
					</a>{' '}
				</span>
			),
			filterMethod: (filter, rows) =>
				matchSorter(
					rows,
					filter.value,
					{ keys: ['blockhash'] },
					{ threshold: matchSorter.rankings.SIMPLEMATCH }
				),
			filterAll: true
		},
		{
			Header: '上一个哈希',
			accessor: 'prehash',
			className: classes.hash,
			Cell: row => (
				<span>
					<ul
						className={classes.partialHash}
						onClick={() => this.handleDialogOpenBlockHash(row.value)}
						href="#/blocks"
					>
						<div className={classes.fullHash} id="showTransactionId">
							{row.value}
						</div>{' '}
						{row.value.slice(0, 6)} {!row.value ? '' : '... '}
					</ul>{' '}
				</span>
			),
			filterMethod: (filter, rows) =>
				matchSorter(
					rows,
					filter.value,
					{ keys: ['prehash'] },
					{ threshold: matchSorter.rankings.SIMPLEMATCH }
				),
			filterAll: true,
			width: 150
		},
		{
			Header: '交易',
			accessor: 'txhash',
			className: classes.hash,
			Cell: row => (
				<ul>
					{!isNull(row.value)
						? row.value.map(tid => (
								<li
									key={tid}
									style={{
										overflow: 'hidden',
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis'
									}}
								>
									<a
										className={classes.partialHash}
										onClick={() => this.handleDialogOpen(tid)}
										href="#/blocks"
									>
										<div className={classes.lastFullHash} id="showTransactionId">
											{tid}
										</div>{' '}
										{tid.slice(0, 6)} {!tid ? '' : '... '}
									</a>
								</li>
						  ))
						: 'null'}
				</ul>
			),
			filterMethod: (filter, rows) =>
				matchSorter(
					rows,
					filter.value,
					{ keys: ['txhash'] },
					{ threshold: matchSorter.rankings.SIMPLEMATCH }
				),
			filterAll: true
		},
		{
			Header: '大小(KB)',
			accessor: 'blksize',
			filterMethod: (filter, rows) =>
				matchSorter(
					rows,
					filter.value,
					{ keys: ['blksize'] },
					{ threshold: matchSorter.rankings.SIMPLEMATCH }
				),
			filterAll: true,
			width: 150
		}
	];

	render() {
		const blockList = this.state.search
			? this.props.blockListSearch
			: this.props.blockList;
		const { transaction, classes } = this.props;
		const { blockHash, dialogOpen, dialogOpenBlockHash } = this.state;
		return (
			<div>
				<div className={`${classes.filter} row searchRow`}>
					<div className={`${classes.filterElement} col-md-3`}>
						<label className="label" style={{ width: 60 }}>
							日期：
						</label>
						<DatePicker
							key={this.state.fromKey}
							showTime
							defaultValue={this.state.from}
							onChange={date => {
								this.setState({ from: date });
							}}
							onOk={date => {
								this.setState({ from: date });
							}}
						/>
					</div>
					<div className={`${classes.filterElement} col-md-3`}>
						<label className="label">至</label>
						<DatePicker
							showTime
							key={this.state.endKey}
							defaultValue={this.state.to}
							onChange={date => {
								this.setState({ to: date });
							}}
							onOk={date => {
								this.setState({ to: date });
							}}
						/>
					</div>
					<div
						className="col-md-3 d-flex"
						style={{
							alignItems: 'center',
							position: 'relative',
							top: '6px'
						}}
					>
						<label className="label" style={{ width: 60, marginTop: 6 }}>
							组织：
						</label>
						<div className="flex-1">
							<MultiSelect
								hasSelectAll
								valueRenderer={this.handleCustomRender}
								shouldToggleOnHover={false}
								selected={this.state.orgs}
								options={this.state.options}
								selectAllLabel="全部组织"
								onSelectedChanged={value => {
									this.handleMultiSelect(value);
								}}
							/>
						</div>
					</div>

					<div className="col-md-1">
						<Button
							className={classes.searchButton}
							color="success"
							disabled={this.state.err}
							onClick={async () => {
								await this.handleSearch();
							}}
						>
							搜索
						</Button>
					</div>
					<div className="col-md-1">
						<Button
							className={classes.filterButton}
							color="primary"
							onClick={() => {
								this.handleClearSearch();
							}}
						>
							重置
						</Button>
					</div>
					<div className="col-md-1">
						<Button
							className={classes.filterButton}
							color="secondary"
							onClick={() => this.setState({ filtered: [], sorted: [] })}
						>
							清空
						</Button>
					</div>
				</div>
				<ReactTable
					data={blockList}
					columns={this.reactTableSetup(classes)}
					defaultPageSize={10}
					list
					filterable
					sorted={this.state.sorted}
					onSortedChange={sorted => {
						this.setState({ sorted });
					}}
					filtered={this.state.filtered}
					onFilteredChange={filtered => {
						this.setState({ filtered });
					}}
					minRows={0}
					style={{ height: '750px' }}
					showPagination={blockList.length >= 5}
				/>

				<Dialog
					open={dialogOpen}
					onClose={this.handleDialogClose}
					fullWidth
					maxWidth="md"
				>
					<TransactionView
						transaction={transaction}
						onClose={this.handleDialogClose}
					/>
				</Dialog>

				<Dialog
					open={dialogOpenBlockHash}
					onClose={this.handleDialogCloseBlockHash}
					fullWidth
					maxWidth="md"
				>
					<BlockView
						blockHash={blockHash}
						onClose={this.handleDialogCloseBlockHash}
					/>
				</Dialog>
			</div>
		);
	}
}

Blocks.propTypes = {
	blockList: blockListType.isRequired,
	currentChannel: currentChannelType.isRequired,
	getTransaction: getTransactionType.isRequired,
	transaction: transactionType
};

Blocks.defaultProps = {
	transaction: null
};

export default withStyles(styles)(Blocks);
