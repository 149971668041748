/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import { withStyles } from '@material-ui/core/styles';
import { Card, Col, Row } from 'antd';
import React, { Component } from 'react';
import block from '../../static/images/common/block.png';
import chainCode from '../../static/images/common/chainCode.png';
import nodes from '../../static/images/common/nodes.png';
import transactions from '../../static/images/common/transactions.png';
import ChartStats from '../Charts/ChartStats';
import OrgPieChart from '../Charts/OrgPieChart';
import PeersHealth from '../Lists/PeersHealth';
import TimelineStream from '../Lists/TimelineStream';
import {
	blockListType,
	dashStatsType,
	peerStatusType,
	transactionByOrgType
} from '../types';
import './DashboardView.css';

/* istanbul ignore next */
const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
		background: {
			backgroundColor: dark ? 'rgb(36, 32, 54)' : '#f0f5f9'
		},
		view: {
			paddingTop: 101,
			paddingLeft: 0,
			width: '80%',
			marginLeft: '10%',
			marginRight: '10%'
		},
		blocks: {
			height: 175,
			marginBottom: 20,
			backgroundColor: dark ? '#453e68' : '#ffffff',
			boxShadow: dark ? '1px 2px 2px rgb(215, 247, 247)' : undefined
		},
		count: {
			marginTop: '55%',
			color: dark ? '#ffffff' : undefined
		},
		statistic: {
			display: 'block',
			float: 'left',
			height: '100%',
			width: '25%',
			textAlign: 'center',
			fontSize: '18pt',
			color: dark ? '#ffffff' : '#000000'
		},
		vdivide: {
			'&::after': {
				borderRight: `2px ${dark ? 'rgb(40, 36, 61)' : '#dff1fe'} solid`,
				display: 'block',
				height: '45%',
				bottom: '55%',
				content: "' '",
				position: 'relative'
			}
		},
		avatar: {
			justifyContent: 'center',
			marginLeft: '60%',
			marginTop: '65%'
		},
		node: {
			color: dark ? '#183a37' : '#21295c',
			backgroundColor: dark ? 'rgb(104, 247, 235)' : '#858aa6'
		},
		block: {
			color: dark ? '#1f1a33' : '#004d6b',
			backgroundColor: dark ? 'rgb(106, 156, 248)' : '#b9d6e1'
		},
		chaincode: {
			color: dark ? 'rgb(121, 83, 109)' : '#407b20',
			backgroundColor: dark ? 'rgb(247, 205, 234)' : '#d0ecda'
		},
		transaction: {
			color: dark ? 'rgb(216, 142, 4)' : '#ffa686',
			backgroundColor: dark ? 'rgb(252, 224, 174)' : '#ffeed8'
		},
		section: {
			height: 335,
			marginBottom: '2%',
			color: dark ? '#ffffff' : undefined,
			backgroundColor: dark ? '#3c3558' : undefined
		},
		center: {
			textAlign: 'center'
		},
		img: {
			width: 50,
			height: 50,
			marginRight: 15
		},
		h1: {
			fontSize: 42,
			color: '#1E2C23'
		},
		h6: {
			fontSize: 14,
			color: '#6E7868',
			marginTop: -10
		},
		card: { marginBottom: 16, boxShadow: '4px 2px 5px rgba(0,0,0,0.1)' }
	};
};

export class DashboardView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			notifications: [],
			hasDbError: false
		};
	}

	componentWillMount() {
		const {
			blockList,
			dashStats,
			peerStatus,
			transactionByOrg,
			blockActivity
		} = this.props;
		if (
			blockList === undefined ||
			dashStats === undefined ||
			peerStatus === undefined ||
			blockActivity === undefined ||
			transactionByOrg === undefined
		) {
			this.setState({ hasDbError: true });
		}
	}

	componentDidMount() {
		const { blockActivity } = this.props;
		this.setNotifications(blockActivity);
	}

	componentWillReceiveProps() {
		const { blockActivity } = this.props;
		this.setNotifications(blockActivity);
	}

	setNotifications = blockList => {
		const notificationsArr = [];
		if (blockList !== undefined) {
			for (let i = 0; i < 3 && blockList && blockList[i]; i += 1) {
				const block = blockList[i];
				const notify = {
					title: `Block ${block.blocknum} `,
					type: 'block',
					time: block.createdt,
					txcount: block.txcount,
					datahash: block.datahash,
					blockhash: block.blockhash,
					channelName: block.channelname
				};
				notificationsArr.push(notify);
			}
		}
		this.setState({ notifications: notificationsArr });
	};

	render() {
		const { dashStats, peerStatus, blockActivity, transactionByOrg } = this.props;
		const { hasDbError, notifications } = this.state;
		if (hasDbError) {
			return (
				<div
					style={{
						height: '100vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<h1>
						Please verify your network configuration, database configuration and try
						again
					</h1>
				</div>
			);
		}
		const { classes } = this.props;
		return (
			<div className={classes.background}>
				<div className={classes.view} style={{ marginBottom: 10 }}>
					<Card className={classes.card}>
						<Row style={{ padding: '6px 0' }}>
							<Col span={6}>
								<div className="d-flex j-center">
									<img alt="" src={block} className={classes.img} />
									<div>
										<h1 className={classes.h1}>{dashStats.latestBlock}</h1>
										<h6 className={classes.h6}>区块</h6>
									</div>
								</div>
							</Col>
							<Col span={6}>
								<div className="d-flex j-center">
									<img alt="" src={transactions} className={classes.img} />
									<div>
										<h1 className={classes.h1}>{dashStats.txCount}</h1>
										<h6 className={classes.h6}>交易</h6>
									</div>
								</div>
							</Col>
							<Col span={6}>
								<div className="d-flex j-center">
									<img alt="" src={nodes} className={classes.img} />
									<div>
										<h1 className={classes.h1}>{dashStats.peerCount}</h1>
										<h6 className={classes.h6}>节点</h6>
									</div>
								</div>
							</Col>
							<Col span={6}>
								<div className="d-flex j-center">
									<img alt="" src={chainCode} className={classes.img} />
									<div>
										<h1 className={classes.h1}>{dashStats.chaincodeCount}</h1>
										<h6 className={classes.h6}>链码</h6>
									</div>
								</div>
							</Col>
						</Row>
					</Card>
					<Row gutter={16}>
						<Col span={12}>
							<Card
								title="节点名称"
								bordered={false}
								className={classes.card + ' jdmc p-0'}
							>
								<PeersHealth peerStatus={peerStatus} />
							</Card>
						</Col>
						<Col span={12}>
							<Card
								title="交易走势"
								bordered={false}
								className={classes.card + ' p-0'}
							>
								<ChartStats />
							</Card>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Card
								title="区块信息"
								bordered={false}
								className={classes.card + '  qkxx p-0'}
							>
								<TimelineStream
									notifications={notifications}
									blockList={blockActivity}
								/>
							</Card>
						</Col>
						<Col span={12}>
							<Card
								title="组织交易"
								bordered={false}
								className={classes.card + ' p-0'}
							>
								<div style={{ paddingBottom: 10 }}>
									<OrgPieChart transactionByOrg={transactionByOrg} />
								</div>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

DashboardView.propTypes = {
	blockList: blockListType.isRequired,
	dashStats: dashStatsType.isRequired,
	peerStatus: peerStatusType.isRequired,
	transactionByOrg: transactionByOrgType.isRequired
};

export default withStyles(styles)(DashboardView);
