/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import find from 'lodash/find';
import React, { Component } from 'react';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import Timeago from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import zhStrings from 'react-timeago/lib/language-strings/zh-CN';
import blockicon from '../../static/images/common/blockicon.png';
import { blockListType, notificationsType } from '../types';
import BlockView from '../View/BlockView';
import './TimelineStream.css';
const formatter = buildFormatter(zhStrings);
/* istanbul ignore next */
const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
		scrollable: {
			height: 300,
			overflowY: 'scroll'
		},
		text: {
			display: 'block',
			flexShrink: 0,
			width: '6em',
			color: dark ? '#ffffff' : undefined,
			'& .badge-secondary': {
				backgroundColor: '#5e548f'
			}
		},
		event: {
			wordWrap: 'break-word',
			width: '90% !important',
			boxShadow: 'none !important',
			background: '#F8F8F8 !important',
			backgroundColor: dark ? '#423b5f !important' : undefined,
			'& p': {
				color: dark ? '#ffffff' : undefined
			},
			'& > div': {
				color: dark ? 'red' : undefined
			}
		},
		open: {
			height: 35,
			marginTop: -10,
			backgroundColor: 'transparent'
		}
	};
};

export class TimelineStream extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dialogOpenBlockHash: false,
			blockHash: {}
		};
	}

	handleDialogOpenBlockHash = rowValue => {
		const { blockList } = this.props;
		const data = find(blockList, item => item.blockhash === rowValue);
		this.setState({
			dialogOpenBlockHash: true,
			blockHash: data
		});
	};

	handleDialogCloseBlockHash = () => {
		this.setState({ dialogOpenBlockHash: false });
	};

	render() {
		const { notifications, classes } = this.props;
		const { blockHash, dialogOpenBlockHash } = this.state;
		return (
			<div>
				<div className={classes.scrollable}>
					<Timeline>
						{notifications.map(item => (
							<TimelineEvent
								key={item.datahash}
								title={item.title}
								icon={<img src={blockicon} width="20" />}
								iconColor="#4E4CB3"
								container="card"
								className={classes.event}
								titleStyle={{ fontWeight: 'bold' }}
								style={{ width: '65%' }}
								cardHeaderStyle={{
									backgroundColor: 'white',
									color: '#4E4CB3',
									fontSize: '13pt'
								}}
								contentStyle={{
									backgroundColor: 'transparent'
								}}
								buttons={
									<>
										<a
											className="blockA"
											href="#/"
											onClick={() => this.handleDialogOpenBlockHash(item.blockhash)}
										>
											详情 >
										</a>
									</>
								}
							>
								<Typography variant="body1" className="text-16">
									<div className="d-flex color6">
										<span className={classes.text}> 通道名称：</span>
										<parent>{item.channelName}</parent>
									</div>
									<div className="d-flex color6">
										<span className={classes.text}> 数据hash：</span>
										<p className="textwrap">{item.datahash}</p>
									</div>
									<div className="d-flex color6">
										<span className={classes.text}> 交易数量：</span>
										<p>{item.txcount}</p>
									</div>
								</Typography>
								<h5 className={classes.text + ' color9 text14 w100'}>
									<Timeago
										date={item.time}
										live={false}
										minPeriod={60}
										formatter={formatter}
									/>
								</h5>
							</TimelineEvent>
						))}
					</Timeline>
				</div>

				<Dialog
					open={dialogOpenBlockHash}
					onClose={this.handleDialogCloseBlockHash}
					fullWidth
					maxWidth="md"
				>
					<BlockView
						blockHash={blockHash}
						onClose={this.handleDialogCloseBlockHash}
					/>
				</Dialog>
			</div>
		);
	}
}

TimelineStream.propTypes = {
	blockList: blockListType.isRequired,
	notifications: notificationsType.isRequired
};

export default withStyles(styles)(TimelineStream);
