/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React from 'react';
import Peers from '../Lists/Peers';
import View from '../Styled/View';
import { peerListType } from '../types';
import './NetworkView.css';
export const NetworkView = ({ peerList }) => (
	<div className="NetworkView">
		<View>
			<Peers peerList={peerList} />
		</View>
	</div>
);

NetworkView.propTypes = {
	peerList: peerListType.isRequired
};

export default NetworkView;
