/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FontAwesome from 'react-fontawesome';
import { Card, CardBody, CardTitle, Table } from 'reactstrap';
import Modal from '../Styled/Modal';
import { blockHashType, onCloseType } from '../types';

const styles = theme => ({
	cubeIcon: {
		color: '#ffffff',
		marginRight: 20
	}
});

export class BlockView extends Component {
	handleClose = () => {
		const { onClose } = this.props;
		onClose();
	};

	render() {
		const { blockHash } = this.props;
		if (!blockHash) {
			return (
				<Modal>
					{modalClasses => (
						<Card className={modalClasses.card}>
							<CardTitle className={modalClasses.title}>
								{/* <FontAwesome name="cube" /> */}
								<div className="titleLeftLine pl-30">区块详细信息</div>
							</CardTitle>
							<CardBody className={modalClasses.body}>
								<span>
									{' '}
									<FontAwesome name="circle-o-notch" size="3x" spin />
								</span>
							</CardBody>
						</Card>
					)}
				</Modal>
			);
		}
		return (
			<Modal>
				{modalClasses => (
					<div className={modalClasses.dialog}>
						<Card className={modalClasses.card}>
							<CardTitle className={modalClasses.title}>
								{/* <FontAwesome name="cube" className={classes.cubeIcon} /> */}
								<div className="titleLeftLine pl-30">区块详细信息</div>
								{/* eslint-disable   */}
								<a onClick={this.handleClose} className={modalClasses.close}>
									×
								</a>
								{/* eslint-disable   */}
							</CardTitle>
							<CardBody className={modalClasses.body}>
								<Table hover>
									<tbody>
										<tr>
											<th>通道名称</th>
											<td>{blockHash.channelname}</td>
										</tr>
										<tr>
											<th>块号</th>
											<td>{blockHash.blocknum}</td>
										</tr>
										<tr>
											<th>创建于</th>

											<td>{moment(blockHash.createdt).format('YYYY-MM-DD HH:mm:ss')}</td>
										</tr>

										<tr>
											<th>交易数量</th>
											<td>{blockHash.txcount}</td>
										</tr>
										<tr>
											<th>块哈希</th>
											<td>
												{blockHash.blockhash}
												<button type="button" className={modalClasses.copyBtn}>
													<div className={modalClasses.copy}>复制</div>
													<div className={modalClasses.copied}>已复制</div>
													<CopyToClipboard text={blockHash.blockhash}>
														<FontAwesome name="clipboard" />
													</CopyToClipboard>
												</button>
											</td>
										</tr>
										<tr>
											<th>数据哈希</th>
											<td>
												{blockHash.datahash}
												<button type="button" className={modalClasses.copyBtn}>
													<div className={modalClasses.copy}>复制</div>
													<div className={modalClasses.copied}>已复制</div>
													<CopyToClipboard text={blockHash.datahash}>
														<FontAwesome name="clipboard" />
													</CopyToClipboard>
												</button>
											</td>
										</tr>
										<tr>
											<th>预哈希</th>
											<td>
												{blockHash.prehash}
												<button type="button" className={modalClasses.copyBtn}>
													<div className={modalClasses.copy}>复制</div>
													<div className={modalClasses.copied}>已复制</div>
													<CopyToClipboard text={blockHash.prehash}>
														<FontAwesome name="clipboard" />
													</CopyToClipboard>
												</button>
											</td>
										</tr>
									</tbody>
								</Table>
							</CardBody>
						</Card>
					</div>
				)}
			</Modal>
		);
	}
}

BlockView.propTypes = {
	blockHash: blockHashType.isRequired,
	onClose: onCloseType.isRequired
};

export default withStyles(styles)(BlockView);
