/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React from 'react';
import Blocks from '../Lists/Blocks';
import View from '../Styled/View';
import {
	blockListType,
	currentChannelType,
	getTransactionType,
	transactionType
} from '../types';
import './BlocksView.css';

export const BlocksView = ({
	blockList,
	currentChannel,
	getTransaction,
	transaction,
	blockListSearch,
	getBlockListSearch,
	transactionByOrg
}) => {
	return (
		<div className="BlocksView">
			<View>
				<Blocks
					blockList={blockList}
					currentChannel={currentChannel}
					transaction={transaction}
					getTransaction={getTransaction}
					transactionByOrg={transactionByOrg}
					blockListSearch={blockListSearch}
					getBlockListSearch={getBlockListSearch}
				/>
			</View>
		</div>
	);
};

BlocksView.propTypes = {
	blockList: blockListType.isRequired,
	currentChannel: currentChannelType.isRequired,
	getTransaction: getTransactionType.isRequired,
	transaction: transactionType
};

BlocksView.defaultProps = {
	transaction: null
};

export default BlocksView;
